
import { defineComponent, PropType, toRefs, ref, reactive, computed } from 'vue'
import httpRequest from '@/service'
import { IFlfgzyState, ILeftParams, IDetailListParams } from '../../../types'
import leftPane from './leftPane.vue'
import rightList from './rightList.vue'

export default defineComponent({
  props: {
    fadeFirstLeftTab: Number,
    state: {
      type: Object as PropType<IFlfgzyState>,
      default: () => {
        return {
          title: '',
          total: 0,
          leftTitle: '',
          typeData: [],
          leftData: [],
          listData: []
        }
      }
    }
  },
  components: {
    leftPane,
    rightList
  },
  setup(props) {
    const curValue = ref()
    const curId = ref()
    let listKey = ''
    let listUrl = ''
    let listLevel = ''
    if (props.state.leftTitle === '法规分类') {
      listKey = 'rulesId'
      listLevel = 'rulesLevel'
      listUrl = 'homeRulesList'
    } else if (props.state.leftTitle === '行政区划') {
      listKey = 'areaId'
      listLevel = 'areaLevel'
    }
    const totalRes = computed(() => {
      if (String(props.state.total).length > 4) {
        return toFixed(props.state.total / 10000, 1) + '万+'
      } else {
        return props.state.total
      }
    })
    /**
     * @param value  任意数值
     * @param count  大于0的整数
     * @returns { string }
     */
    const toFixed = function (value: number, count: number): string {
      const [integer, decimal = '0'.repeat(count)] = value.toString().split('.')
      // 加0是为了第二种情况
      return integer + '.' + (decimal + '0'.repeat(count - 1)).substr(0, count)
    }

    // 类别
    const curType = reactive({
      type: 'effectId',
      name: '效力级别：' + props.state.typeData[0].name,
      id: props.state.typeData[0].id
    })
    // 控制左侧栏显示
    let leftPaneFlag = ref(true)
    // 效力级别切换，切换中央法规左侧列表更新，切换地方法规左侧不更新
    const handleTypeClick = (e: any) => {
      if (curType.id === e.id) return
      curType.id = e.id
      curType.name = '效力级别：' + e.name
      if (listKey === 'rulesId') {
        // 点击除法律外的级别 左侧栏消失
        // if (e.id === '2') {
        //   leftPaneFlag.value = true
        // } else {
        //   leftPaneFlag.value = false
        // }
        // leftPaneFlag.value = false
        leftList()
      } else {
        detailList(curLeft)
      }
    }
    // 左侧
    const curLeftData = ref(props.state.leftData)
    let curLeft = reactive({
      type: listKey,
      name: props.state.leftTitle + '：' + curLeftData.value[0].name,
      id: curLeftData.value[0].id,
      level: curLeftData.value[0].level
    })
    const getLeftData = (params: ILeftParams) => {
      return httpRequest.post({ url: listUrl, params: params })
    }
    async function leftList() {
      const stagingData = await getLeftData({
        effectLevelId: curType.id
      })
      curLeftData.value = stagingData.data
      if (stagingData.data.length) {
        curLeft.id = curLeftData.value[0].id
        curLeft.level = curLeftData.value[0].level
        curLeft.name = props.state.leftTitle + '：' + curLeftData.value[0].name

        detailList(curLeft)
      } else {
        curLeft.id = ''
        curLeft.name = ''
        curLeft.level = ''
        curListData.value = []
      }
    }
    const handleLeftClick = (e: any) => {
      if (curLeft.id === e.id) return
      curLeft.id = e.id
      curLeft.level = e.level
      curLeft.name = props.state.leftTitle + '：' + e.name
      detailList(curLeft)
    }

    curValue.value = [curType, curLeft]
    curId.value = {
      [curType.type]: curType.id,
      [curLeft.type]: curLeft.id,
      [listLevel]: curLeft.level
    }

    // 右侧列表
    const curListData = ref(props.state.listData)
    const getDetailListData = (params: IDetailListParams) => {
      return httpRequest.post({ url: 'homeDetailList', params: params })
    }
    async function detailList(curLeft: any) {
      curValue.value = [curType, curLeft]
      curId.value = {
        [curType.type]: curType.id,
        [curLeft.type]: curLeft.id,
        [listLevel]: curLeft.level
      }
      const stagingData = await getDetailListData({
        effectId: curType.id,
        [listKey]: curLeft.id,
        [listLevel]: curLeft.level
      })
      curListData.value = stagingData.data
    }

    return {
      ...toRefs(props.state),
      totalRes,
      curValue,
      curType,
      curLeft,
      curId,
      leftPaneFlag,
      handleTypeClick,
      handleLeftClick,
      curLeftData,
      curListData
    }
  }
})
