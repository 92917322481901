
import { defineComponent, ref, watch } from 'vue'
import pagination from '@/components/pagination/pagination.vue'
import { IComObj } from '@/global/types'
export default defineComponent({
  name: 'leftPane',
  components: {
    pagination
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  emit: ['change'],
  setup(props, { emit }) {
    const curData = ref<IComObj>(props.data.slice(0, 10))

    watch(
      () => props.data,
      () => {
        curData.value = props.data.slice(0, 10)
      }
    )

    const handleItemClick = (e: any) => {
      emit('change', e)
    }
    const handlePageClick = (data: any[]) => {
      curData.value = data
    }
    return {
      curData,
      handleItemClick,
      handlePageClick
    }
  }
})
