
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ArrowLeftBold,ArrowRightBold } from '@element-plus/icons-vue'
// import httpRequest from '@/service'
export default defineComponent({
  components: {
    ArrowLeftBold,
    ArrowRightBold
  },
  setup() {
    // interface IListRef {
    //   title?: string
    //   [key: string]: any
    // }
    const router = useRouter()
    // const listRef = reactive<IListRef>({
    //   title: '',
    //   listData: []
    // })
    const listRef = {
      title: '业务赋能',
      listData: [
        {
          type:'依法治省（市）',
          link:'',
          img:'ywfn-icon0.png',
          list:[],
          text: '纵向贯通下级法治建设议事协调机构，横向联通本级立法、执法、司法、守法四个协调小组和法治建设各部门，实现示范创建、任务督办、法治督察、法治备案、法治政府考核，切实发挥依法治省（市）办统筹、协调、督促、检查、推动的工作职责'
        },
        {
          type: '智慧立法',
          link: '',
          img: 'ywfn-icon1.png',
          list: [
            {
              name: '智能辅助起草',
              link: ''
            },
            {
              name: '智能审查',
              link: 'http://192.168.3.184:18087/znh/index.html#id=tmuflx&p=%E6%99%BA%E8%83%BD%E5%AE%A1%E6%9F%A5&g=1'
            },
            {
              name: '智能版本比对',
              link: 'http://192.168.3.184:18087/fzzyk/smartTools/compare'
            },
            {
              name: '立法意见智能汇总',
              link: ''
            },
            {
              name: '智能清理',
              link: ''
            }
          ],
          text: '支撑各级行政立法机关，从立法计划、起草、审查、公布、清理、评估的全过程管理，为立法工作有效开展，提供动态化管理、智能化应用，切实提升立法质量和立法效率'
        },
        {
          type: '智慧执法',
          link: '',
          img: 'ywfn-icon3.png',
          list: [
            {
              name: '智能证照识别',
              link: 'http://192.168.3.184:18087/fzzyk/smartTools/licenseRecognition'
            },
            {
              name: '智能归档',
              link: ''
            },
            {
              name: '智能生成行政处罚决定书',
              link: ''
            },
            {
              name: '智能推送相似案件',
              link: ''
            },
            {
              name: '智能推荐裁量权',
              link: ''
            }
          ],
          text: '面向各级、各领域行政执法机关，以三项制度有效落实、便捷高效执法为核心目标，利用大数据、人工智能、区块链、云技术等技术，构建智慧行政执法平台，提供勤务管理、执法办案、移动执法、指挥调度、决策分析等功能，使勤务安排更合理、执法办案更规范、指挥调度更精准、决策分析更科学，充分提升执法效能'
        },
        {
          type: '智慧监督',
          link: '',
          img: 'ywfn-icon4.png',
          list: [
            {
              name: '智能变动提醒',
              link: ''
            },
            {
              name: '智能案卷评查',
              link: ''
            },
            {
              name: '智能涉刑预判',
              link: ''
            },
            {
              name: '裁量风险监控',
              link: ''
            },
            {
              name: '同案不同判',
              link: ''
            },
            {
              name: '大数据分析',
              link: ''
            }
          ],
          text: '面向各级行政执法监督部门及监督人员，围绕数据采集利用、资格管理、队伍建设等业务，实现全口径数据采集、全视角系统监督、可视化运行监控、立体式功效能评价、数字化支撑决策的监督体系'
        },
        {
          type: '智慧复议',
          link: '',
          img: 'ywfn-icon2.png',
          list: [
            {
              name: '智能问答',
              link: ''
            },
            {
              name: '智能预测复议结果',
              link: ''
            },
            {
              name: '智能生成复议决定书',
              link: ''
            }
          ],
          text: '依托各级电子政务资源，面向各级行政复议机关和社会公众，提供网上申请、案件登记、立案受理、案件审理、结案归档的全流程管理。让行政复议“入门”更加简单，实现由“最多跑一次”到“一次不用跑”的根本转变，使正义“触手可及”'
        },
        {
          type:'公共法律服务',
          link:'',
          img:'ywfn-icon7.png',
          list:[],
          text: '以建设“智慧型”公共法律服务网络平台为切入点，整合律师、公证、仲裁、法律援助、司法鉴定、人民调解等资源，融合实体、网络、热线、项目四个服务平台，形成全业务覆盖、全时空服务的格局，实现公共法律服务便民化、均等化、精准化'
        },
        // {
        //   type:'业务应用',
        //   link:'',
        //   img:'ywfn-icon8.png',
        //   list:[]
        // },
        // {
        //   type: '数据中台',
        //   link: '',
        //   img: 'ywfn-icon5.png',
        //   list: [
        //     {
        //       name: '智能辅助起草',
        //       link: ''
        //     },
        //     {
        //       name: '智能审查',
        //       link: 'http://192.168.3.184:18087/znh/index.html#id=tmuflx&p=%E6%99%BA%E8%83%BD%E5%AE%A1%E6%9F%A5&g=1'
        //     },
        //     {
        //       name: '智能版本比对',
        //       link: 'http://192.168.3.184:18087/znh/index.html#id=qcjma4&p=%E6%99%BA%E8%83%BD%E5%AF%B9%E6%AF%94'
        //     },
        //     {
        //       name: '立法意见智能汇总',
        //       link: ''
        //     },
        //     {
        //       name: '智能清理',
        //       link: ''
        //     }
        //   ]
        // },
        // {
        //   type: '知识中台',
        //   link: '',
        //   img: 'ywfn-icon6.png',
        //   list: [
        //     {
        //       name: '智能辅助起草',
        //       link: ''
        //     },
        //     {
        //       name: '智能审查',
        //       link: 'http://192.168.3.184:18087/znh/index.html#id=tmuflx&p=%E6%99%BA%E8%83%BD%E5%AE%A1%E6%9F%A5&g=1'
        //     },
        //     {
        //       name: '智能版本比对',
        //       link: 'http://192.168.3.184:18087/znh/index.html#id=qcjma4&p=%E6%99%BA%E8%83%BD%E5%AF%B9%E6%AF%94'
        //     },
        //     {
        //       name: '立法意见智能汇总',
        //       link: ''
        //     },
        //     {
        //       name: '智能清理',
        //       link: ''
        //     }
        //   ]
        // }
      ]
    }

    const bgIcon = (e: any) => {
      return `url('` + require(`@/assets/images/home/${e}`) + `')`
    }
    const bgImage = (e: any) => {
      const name = e.split('.')[0] + '-1.png'
      return `url('` + require(`@/assets/images/home/${name}`) + `')`
    }
    // 当前类型
    const curType = ref(0)
    const typeClick = (index: number) => {
      curType.value = index
    }
    //左右滚动
    const ul = ref()
    function ulScrollX(x:number){
      let base = parseInt(ul.value.style.left) || 0
      let width = 190
      let count = listRef.listData.length - 6
      if(x>0){
        ul.value.style.left = (base - width)<-width*count?-width:base - width + 'px'
      }else{
        ul.value.style.left = (base + width)>0?0:base + width + 'px'
      }
      console.log(ul.value.style.left);
    }
    //跳转
    const jumpClick = (i: any) => {
      const urlList = ['/GovernByLaw','/WLegislation','/WEnforce','/WSupervision','/WReconsideration','/LegalServices']
      const { href } = router.resolve({
        path: urlList[i]
      })
      window.open(href, '_blank')
    }
    return {
      listRef,
      curType,
      typeClick,
      bgIcon,
      bgImage,
      jumpClick,
      ul,
      ulScrollX
    }
  }
})
