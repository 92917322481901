import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-dce7cd94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "right-list" }
const _hoisted_2 = { class: "items" }
const _hoisted_3 = { class: "sub-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h4", {
        class: _normalizeClass([_ctx.flag ? 'title' : 'titleNoLogo'])
      }, _toDisplayString(_ctx.flag ? _ctx.title : ''), 3 /* TEXT, CLASS */),
      (_ctx.data.length)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "more",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMoreClick && _ctx.handleMoreClick(...args)))
          }, "更多"))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "item",
          key: index
        }, [
          _createVNode(_component_router_link, {
            class: "title",
            target: "_blank",
            to: { name: 'DetailPage', query: { id: item.id } }
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"]),
          _createElementVNode("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subTitle, (it, ind) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "it",
                key: ind
              }, _toDisplayString(it), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}