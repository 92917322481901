import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5dbe483a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "left-pane" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "items" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.curData, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(['item', { active: _ctx.id === item.id }]),
          key: index,
          onClick: ($event: any) => (_ctx.handleItemClick(item))
        }, _toDisplayString(item.name), 11 /* TEXT, CLASS, PROPS */, _hoisted_4))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createCommentVNode(" 分页 "),
    _createVNode(_component_pagination, {
      data: _ctx.data,
      onChange: _ctx.handlePageClick
    }, null, 8 /* PROPS */, ["data", "onChange"])
  ]))
}