// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/home/icon-title.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".part[data-v-24c3818d] {\n  height: 720px;\n}\n.part .label[data-v-24c3818d] {\n  line-height: 80px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.part .label .icon[data-v-24c3818d] {\n  width: 30px;\n  height: 27px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.part .label .title[data-v-24c3818d] {\n  font-size: 24px;\n  color: #2e6cc1;\n  margin: 0 10px;\n  font-weight: 500;\n}\n.part .label .total[data-v-24c3818d] {\n  height: 20px;\n  line-height: 20px;\n  color: #fff;\n  background-color: #f55558;\n  border-radius: 10px;\n  padding: 0 10px;\n}\n.part .type-list[data-v-24c3818d] {\n  margin-bottom: 20px;\n}\n.part .type-list ul[data-v-24c3818d] {\n  display: flex;\n}\n.part .type-list ul li.item[data-v-24c3818d] {\n  flex: 1;\n  line-height: 40px;\n  color: #333;\n  font-size: 18px;\n  background-color: #fff;\n  margin: 0 10px;\n  border-radius: 20px;\n  text-align: center;\n  cursor: pointer;\n  transition: all 0.2s;\n}\n.part .type-list ul li.item.active[data-v-24c3818d] {\n  background-color: #2e6cc1;\n  color: #fff;\n}\n.part .type-list ul li.item[data-v-24c3818d]:first-child {\n  margin-left: 0;\n}\n.part .type-list ul li.item[data-v-24c3818d]:last-child {\n  margin-right: 0;\n}\n.part .type-list.short-list ul[data-v-24c3818d] {\n  justify-content: center;\n}\n.part .type-list.short-list ul li.item[data-v-24c3818d] {\n  flex: 0 1 auto;\n  padding: 0 30px;\n}\n.part .container[data-v-24c3818d] {\n  height: 580px;\n  display: flex;\n}\n.part .container .left[data-v-24c3818d] {\n  width: 240px;\n  margin-right: 15px;\n  background-color: #fff;\n  border-radius: 15px;\n}\n.part .container .right[data-v-24c3818d] {\n  flex: 1;\n  background-color: #fff;\n  border-radius: 15px;\n}\n", ""]);
// Exports
module.exports = exports;
