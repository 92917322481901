
import { defineComponent, reactive } from 'vue'
import { IFlfgzyState, IDetailListParams, ILeftParams } from '../../types'
import httpRequest from '@/service'
import item from './cpns/flfgzyItem.vue'
export default defineComponent({
  name: 'index',
  components: {
    item
  },
  setup() {
    const state: IFlfgzyState[] = reactive([])
    // 效力级别
    const getTypeListData = () => {
      return httpRequest.post({ url: 'homeEffectLevelList' })
    }
    // 法规分类
    const getRulesListData = (params: ILeftParams) => {
      return httpRequest.post({ url: 'homeRulesList', params: params })
    }
    // 行政区划
    const getAreaListData = (params: ILeftParams) => {
      return httpRequest.post({ url: 'homeAreaList', params: params })
    }
    // 列表
    const getDetailListData = (params: IDetailListParams) => {
      return httpRequest.post({ url: 'homeDetailList', params: params })
    }
    async function getItemState() {
      const typeListData = await getTypeListData()

      for (let [index, item] of typeListData.data.entries()) {
        const stateItem: IFlfgzyState = {
          title: '',
          total: 0,
          leftTitle: '',
          typeData: [],
          leftData: [],
          listData: []
        }

        stateItem.title = item.type
        stateItem.total = item.total
        stateItem.typeData = item.list
        let listKey = ''

        if (index === 0) {
          const rulesListData = await getRulesListData({
            effectLevelId: stateItem.typeData[0].id
          })
          stateItem.leftTitle = '法规分类'
          stateItem.leftData = rulesListData.data
          listKey = 'rulesId'
        } else if (index === 1) {
          const areaListData = await getAreaListData({
            effectLevelId: stateItem.typeData[0].id
          })
          stateItem.leftTitle = '行政区划'
          stateItem.leftData = areaListData.data
          listKey = 'areaId'
        }
        const detailListData = await getDetailListData({
          effectId: stateItem.typeData[0].id,
          [listKey]: stateItem.leftData[0].id
        })
        stateItem.listData = detailListData.data
        state.push(stateItem)
      }
    }
    getItemState()
    return {
      state
    }
  }
})
