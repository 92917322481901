
import { ref, watch, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'rightList',
  props: {
    curValue: {
      type: Array,
      default: () => {
        return []
      }
    },
    curId: {
      type: Object,
      default: () => {
        return {}
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    flag: {
      type: Boolean
    }
  },
  setup(props: any) {
    const store = useStore()
    const router = useRouter()
    const title = ref(props.curValue[1].name.split('：')[1])
    watch(
      () => props.data,
      () => {
        title.value = props.curValue[1].name.split('：')[1]
      }
    )
    const handleMoreClick = () => {
      store.commit('setForm', {
        ...store.state.form,
        ...props.curId
      })
      console.log('props.curValue', props.curValue, store.state.form)
      if (
        // (props.curValue[1].id === '0000' && props.curValue[0].id === '2') ||
        props.curValue[1].id === '0000' ||
        props.curValue[1].id === '000000'
      ) {
        store.commit('setSearchList', [props.curValue[0]])
      } else {
        store.commit('setSearchList', props.curValue)
      }
      // store.commit('setSearchList', props.curValue)
      router.push('/SearchList')
    }
    return {
      title,
      handleMoreClick
    }
  }
})
