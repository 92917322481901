
import { defineComponent, reactive } from 'vue'
import { IComObj } from '@/global/types'
import { useRouter } from 'vue-router';
import httpRequest from '@/service'
export default defineComponent({
  setup() {
    const router = useRouter();
    const goToDestination = (type: string) => {
      console.log('type',type)
      router.push({ name: 'SearchCaseList',params:{type} });
    };
    const listRef = reactive<IComObj>({
      titleOne: '',
      titleTwo: '',
      totalOne: '',
      totalTwo: '',
      lisDataOne: [],
      lisDataTwo: []
    })
    // 行政执法案例
    const getEnforceData = () => {
      httpRequest
        .post({ url: 'homeEnforceCase' })
        .then((res: any) => {
          listRef.titleOne = res.data.title
          listRef.totalOne = res.data.total
          listRef.lisDataOne = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    }
    // 行政复议案例
    const getReconsiderData = () => {
      httpRequest
        .post({ url: 'homeReconsiderCase' })
        .then((res: any) => {
          listRef.titleTwo = res.data.title
          listRef.totalTwo = res.data.total
          listRef.lisDataTwo = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    }

    getEnforceData()
    getReconsiderData()

    return { listRef,goToDestination}
  }
})
