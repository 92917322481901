// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/home/title-icon.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".flfgzy[data-v-1e31a629] {\n  height: 1475px;\n}\n.flfgzy .title[data-v-1e31a629] {\n  position: relative;\n  font-size: 30px;\n  color: #333;\n  text-align: center;\n  font-weight: 500;\n  margin-top: 30px;\n}\n.flfgzy .title[data-v-1e31a629]::before {\n  position: absolute;\n  content: '';\n  left: 0;\n  top: calc(50% - 5px);\n  width: 497px;\n  height: 11px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.flfgzy .title[data-v-1e31a629]::after {\n  position: absolute;\n  content: '';\n  right: 0;\n  top: calc(50% - 5px);\n  width: 497px;\n  height: 11px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
